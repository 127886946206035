

const Health = () => {
    return(
        <div className="texts-bck">
            <p className='textBiggest box'>Not here yet</p>
        </div>
    )
}

export default Health